import NotesAdd from '../shared/drawers/notesAdd';
import NotesDrawer from '../shared/drawers/notesDrawer';
import NotesView from '../shared/drawers/notesView';
import Calendar from './modules/calander';
import Attachments from './modules/attachmentsTable';
import SelectedProduct from './modules/blockSelectProductTable';
import BookInstall from './modules/bookInstallModal';
import InstallDrawer from './modules/installDraw';
import DevicePDI from './modules/devicePDI';
import Installchecklist from './tables/installChecklistTable';
import OptionsContainer from './modules/optionsContainer';
import InstallFiltersDrawer from './modules/installFiltersDrawer';
import DeviceModalContainer from './modules/deviceModalContainer';
import DealFiltersDrawer from './modules/dealFiltersDrawer';


const exported = {
  NotesAdd,
  NotesDrawer,
  NotesView,
  Calendar,
  Attachments,
  SelectedProduct,
  BookInstall,
  InstallDrawer,
  DevicePDI,
  Installchecklist,
  OptionsContainer,
  InstallFiltersDrawer,
  DeviceModalContainer,
  DealFiltersDrawer
}
export default exported;