
import { useState, useEffect } from 'react';
import UILIB from 'components';
import axios from 'classes/axios'
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";


export default function BlockOpenQuotes() {
    const history = useHistory();
    const account = useSelector(state => state.account)
    const [openQuotes, setOpenQuotes] = useState({ count: 0 });

    useEffect(() => {
      const asyncEffect = async () => {
        const quotes = await axios.get('/entities/workflow/deals/actionGetSalesManagerQuickQuote');
        setOpenQuotes(quotes.data.result[0]);
      }
      asyncEffect();
    }, [account]);


    return <UILIB.Paper className="text-center flex-container column height-100">
            <h2 className="flex-item start width-100">Open Quotes</h2>
            <div className="flex-container flex-1 column evenly">
              <div>
                <h1 className="mar-b0">{openQuotes.count}</h1>
                <p className="text-grey text-small">Total Open Quotes</p>
              </div>
            </div>
            <div className="flex-container row between width-100 mar-t5">
              <div className="mar-b5 start flex-container consumablesHub buttonSize">
                <UILIB.Button
                  className='primary small consumablesHub buttonSize'
                  value="View All Quotes"
                  onClick={() => { history.push('/salesHub/accountmanagerview/quoteview'); }} />
              </div>
              <div className="mar-b5 end flex-container consumablesHub buttonSize">
                <UILIB.Button
                  className='primary small consumablesHub buttonSize'
                  value="View Draft Orders"
                  onClick={() => { history.push('/salesHub/accountmanagerview/draftview'); }} />
              </div>
            </div>
          </UILIB.Paper >

}