import { useState, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getToastMessage } from '../functions';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getUserPortalErrors } from 'classes/formErrors/repository';
import { createButton, createTextInput, createSelectInput, yesNo } from 'classes/controls/formControls';


export default function EditUserDrawer({ entity, sync, onClose }) {


  const defaultError = { error: false, message: '' };
  const defaultFormErrors = { syncInvNo: defaultError, syncInvDate: defaultError };
  const [bannerError, setBannerError] = useState(defaultError);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [formData, setFormData] = useState({ ...entity });
  const [emailSaving, setEmailSaving] = useState(false);
  const [passwordSaving, setPasswordSaving] = useState(false);
  const [syncing, setSyncing] = useState({ syncIsActive: false });
  const [saving, setSaving] = useState(false);
  const exceptions = [204,400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const successMessage = 'User record updated';


  const actionUpdateEntity = (event) => {
    setFormData({  ...formData, [event.target.name]: event.target.value });
  };

  const actionUpdateField = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const actionSaveForm = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const formResult = await checkFormErrors(getUserPortalErrors(formData), setFormErrors, null, null, formData, null);
      if(formResult.errorCount === 0) {
        const result = await Axios.put(`/entities/portalsV3_userbase/${entity.id}`, { entity: formData }); 
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          Object.keys(result.data.result[0]).forEach(x => entity[x] = result.data.result[0][x]);
          sync.set(!sync.get);
          onClose();
        }
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    } finally {
      setSaving(false);
    }
  }

  const actionSyncData = async (event, value, field) => {
    try {
      setSyncing({ ...syncing, [event.target.name]: true });
      const result = await Axios.patch(`/entities/workflow/portals/actionUserActiveStatus/`, formData); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) toast.error(invalidConfig);
        if(result.status === 404) toast.error(notFoundError);
      } else {
        toast.info(successMessage);
        entity[field] = value;
        sync.set(!sync.get);
      }
    } catch (error) {
      console.log(error);
      toast.error(exceptionError);
    }
    setSyncing({ ...syncing, [event.target.name]: false });
  };

  const handleSendWelcome = async (row) => {
    try { 
      setEmailSaving(true);
      const respose = await Axios.put(`/entities/workflow/portals/actionResendWelcome/${row.id}`);
      if(exceptions.includes(respose.status)) {
        if(respose.status === 400) toast.error(invalidConfig);
        if(respose.status === 404) toast.error(notFoundError); 
      } else {
        toast.info(getToastMessage(row, 1));
      }
      setEmailSaving(false);
    } catch (err) {
      console.log(err);
      setEmailSaving(false);
      toast.error(exceptionError);
    }
  };

  const handleSendPassword = async (row) => {
    try { 
      setPasswordSaving(true);
      const respose = await Axios.post(`/entities/workflow/portals/actionResetPasswordEmail/${row.id}`);
      if(exceptions.includes(respose.status)) {
        if(respose.status === 400) toast.error(invalidConfig);
        if(respose.status === 404) toast.error(notFoundError); 
      } else {
        toast.info(getToastMessage(row, 2));
      }
      setPasswordSaving(false);
    } catch (err) {
      console.log(err);
      setPasswordSaving(false);
      toast.error(exceptionError);
    }
  };


  return <Fragment>
    <div className="flex-container row width-100">
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b10 width-100">User Edit</div>
      <div className='flex-container row start width-100'>
        {createTextInput('First Name *', 'firstName', (saving || Boolean(entity.status === 2)), formData.firstName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Last Name *', 'lastName', (saving || Boolean(entity.status === 2)), formData.lastName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Email Address *', 'emailAddress', (saving || Boolean(entity.status === 2)), formData.emailAddress, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className='flex-container row end width-100 mar-t2 mar-l5 mar-r5'>
        <UILIB.Button className='green' disabled={(saving || Boolean(entity.status === 2))} loading={saving} value='Update' onClick={async () => await actionSaveForm()} />
      </div>
      {bannerError.error && <div className="flex-container row end width-100 mar-l5">
        <div className="errored message">{bannerError.message}</div>
      </div>}
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b10 width-100">User Options</div>
      <div className="flex-container row start width-100">
        {createSelectInput('Is Active', 'isActive', (Boolean(entity.status === 2) || syncing.syncIsActive), formData.isActive, yesNo, (ev) => actionUpdateEntity(ev), null, 'flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
        {createButton('', 'syncIsActive', 'Apply', (Boolean(entity.status === 2) || syncing.syncIsActive), syncing.syncIsActive, async (ev) => await actionSyncData(ev, Number(formData.isActive), 'isActive'), null, 'flex-item end min-width-150', null, null, null, 'Attempt to Sync with VO')}
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start flex-grow-1 center text-13 pad-l5">Re-Send Welcome Email</p>
        {createButton('', 'sendWelcome', 'Send', Boolean(entity.status === 2), emailSaving, async () => await handleSendWelcome(entity), null, 'flex-item end min-width-150')}
      </div>
      <div className="flex-container row width-100">
        <p className="flex-item start flex-grow-1 center text-13 pad-l5">Send Password Reminder</p>
        {createButton('', 'sendPassword', 'Send', Boolean(entity.status === 2), passwordSaving, async () => await handleSendPassword(entity), null, 'flex-item end min-width-150')}
      </div>
      <div className="flex-item center align-center font-weight-bold text-16 mar-t20 mar-b10 width-100">User Information</div>
      <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
        <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Created Date:</p>
        <p className="flex-item start flex-basis-60 text-13">{(entity.createdAt) ? moment(entity.createdAt).utc().format('DD/MM/YYYY') : 'Unknown'}</p>
      </div>
      <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
        <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Updated At:</p>
        <p className="flex-item start flex-basis-60 text-13">{(entity.updatedAt) ? moment(entity.updatedAt).utc().format('DD/MM/YYYY') : 'Unknown'}</p>
      </div>
      <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
        <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20 width-40">Updated By:</p>
        <p className="flex-item start flex-basis-60 text-13">{(entity.updatedByTxt) ? entity.updatedByTxt : 'Unknown'}</p>
      </div>
      <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
        <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20 width-40">Updated At (VO):</p>
        <p className="flex-item start flex-basis-60 text-13">{(entity.modifiedDate) ? moment(entity.modifiedDate).utc().format('DD/MM/YYYY') : 'Unknown'}</p>
      </div>
      <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
        <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20 width-40">Updated By (VO):</p>
        <p className="flex-item start flex-basis-60 text-13">{(entity.modifiedBy) ? entity.modifiedBy : 'Unknown'}</p>
      </div>
    </div>
  </Fragment>

}
