
import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'
import { useHistory } from "react-router-dom";
import { Fragment } from 'react';
export default function BlockServiceAlerts({ user }) {

    const history = useHistory();
    const [pageWidth, setPageWidth] = useState([]);
    const [serviceAlerts, setServiceAlerts] = useState(null);

    useEffect(() => {
        async function grabStats() {
            const macDown = await Axios.get('/entities/workflow/deals/actionGetSalesManagerMacDown/2')
            const GTFive = await Axios.get('/entities/workflow/deals/actionGetSalesManagerGTFive/2')
            const calloutDueRenewal = await Axios.get('/entities/workflow/deals/actionGetSalesManagerRenewal/2')
            setServiceAlerts([macDown.data.result, GTFive.data.result, calloutDueRenewal.data.result]);
        }
        grabStats()
    }, []);


    useEffect(() => {
      new ResizeObserver((ev) => {
        if(pageWidth < 1000 && ev[0].contentRect.width > 1000) setPageWidth(ev[0].contentRect.width);
        if(pageWidth > 1000 && ev[0].contentRect.width < 1000) setPageWidth(ev[0].contentRect.width);
      }).observe(document.body)
    });


    let flexClass = {
      serviceAlertButton: pageWidth > 1000 ? "" : "width-100"
    }

    
    return <UILIB.Paper className="flex-container row width-100 height-100">
        <div className="width-100">
            <h2>Service Alerts</h2>
            {Boolean(serviceAlerts) && <Fragment>
                {!serviceAlerts.length && <div>
                    You have no Service Alerts
                </div>}
                {Object.values(serviceAlerts).map((alertStats, index) => {
                    return <UILIB.Paper key={"quote_" + index} className={"mar-b15 red"}>
                    <div className="flex-container between">
                        <div className="text-white text-medium">{(alertStats[0] && alertStats[0].StatName) || ''}</div>
                        <div style={{ width: "20px" }}>
                            <div className="text-white text-medium">{(alertStats[0] && alertStats[0].theCnt) || 0}</div>
                        </div>
                    </div>
                    </UILIB.Paper>
                })}
            </Fragment>}
            {!serviceAlerts && <UILIB.Loading type={3} />}
        </div>
        <div className="flex-item flex-grow-1 end width-100">
            <UILIB.Button 
              className={`primary small ${flexClass.serviceAlertButton}`}
              value="View All" 
              onClick={() => { history.push('/salesHub/accountmanagerview/customers/servicealerts') }} 
            />
        </div>
    </UILIB.Paper>
}