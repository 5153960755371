import Modules from './modules';
import { setDrawer } from 'store/actions';
import { BsInfoCircle } from "react-icons/bs";

export async function actionOpenKitFinderDrawer(type, kitData, dispatch, enabled) {
  if(!enabled) return false;

  const selector = (type, data) => {
    if(type === 'ViewKitFinderDrawer') return <Modules.KitFinderDrawer kitData={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} isActive={true} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: "600px",
    content: selector(type, kitData)
  }))
}

export function getTableHeaderWithInfoIcon(toolTip, headerText) {
  
    return <div className='flex-container row nowrap width-100'>
      <div className="flex-item align-center min-width-15 mar-r5"><BsInfoCircle className="reactIcons red text-12" title={toolTip}  /></div>
      <div className="flex-item align-center flex-grow-1">{headerText}</div>
    </div>;
}