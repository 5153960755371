
import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'
import { formatCurrency } from 'classes/format';
import { useHistory } from "react-router-dom";

export default function BlockActiveDeals({ user }) {
    const history = useHistory();
    const [activeDeals, setActiveDeals] = useState({
      unapproved: 0, unapprovedGp: 0, active: 0, activeGp: 0, onHold: 0, onHoldGP: 0 });

    useEffect(() => {
      const asyncEffect = async () => {
        const quotes = await Axios.get('/entities/workflow/deals/actionGetSalesManagerDealSummary');
        setActiveDeals(quotes.data.result);
      }
      asyncEffect();
    }, []);

    const unapproved = activeDeals.unapproved;
    const unapprovedAmt = activeDeals.unapprovedGp;

    const active = activeDeals.active;
    const activeAmt = activeDeals.activeGp;

    const onHold = activeDeals.onHold;
    const onHoldAmt = activeDeals.onHoldGP;

    return <UILIB.Paper className="flex-container row width-100 height-100">
        <div className="fullwidth width-100">
            <h2 className="text-center">Active Deals</h2>
            <UILIB.Paper className={"mar-b15 red"}>
                <div className="flex-container between">
                    <div className="text-medium text-white flex-item flex-grow-1">Unapproved Orders</div>
                    <div className="text-small mar-l10 mar-r30 flex-item flex-items-center">Qty: {unapproved}</div>
                    <div className="text-small mar-l30 flex-item flex-items-center">GP: {formatCurrency(unapprovedAmt)}</div>
                </div>
            </UILIB.Paper>
            <UILIB.Paper className={"mar-b15 red"}>
                <div className="flex-container between">
                    <div className="text-medium text-white flex-item flex-grow-1">Active Orders</div>
                    <div className="text-small mar-l10 mar-r30 flex-item flex-items-center">Qty: {active}</div>
                    <div className="text-small mar-l30 flex-item flex-items-center">GP: {formatCurrency(activeAmt)}</div>
                </div>
            </UILIB.Paper>
            <UILIB.Paper className={"mar-b15 red"}>
                <div className="flex-container between">
                    <div className="text-medium text-white flex-item flex-grow-1">On-hold Orders</div>
                    <div className="text-small mar-l10 mar-r30 flex-item flex-items-center">Qty: {onHold}</div>
                    <div className="text-small mar-l30 flex-item flex-items-center">GP: {formatCurrency(onHoldAmt)}</div>
                </div>
            </UILIB.Paper>
        </div>
        <div className="flex-container row start mar-t5 width-100 ">
          <div className="flex-container width-100 mar-b5 mar-t5 ">
            <UILIB.Button 
              className={`primary small consumablesHub buttonSize`}
              value="View All Active" 
              onClick={() => { history.push('/salesHub/accountmanagerview/deals/active') }} 
            />
          </div>
        </div>
    </UILIB.Paper>
}