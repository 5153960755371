import { Fragment } from 'react';
import { createSelectInput } from 'classes/controls/formControls';
import { BsArrowDown } from "react-icons/bs";


export default function SelectUser({ formData, saving, actionUpdateField }) {

  return <Fragment>
    <div className="flex-item center align-center row width-100 mar-t5">
      <BsArrowDown />
    </div>
    <div className="flex-item start row width-100 mar-t5">
      <div className="flex-item center align-center font-weight-bold text-14 width-100">Select User</div>
      <p className="flex-item start width-80 text-11 mar-l5 mar-t2">
        Please choose a User or Admin from the following list who should become the new Owner
      </p>
    </div>
    {createSelectInput('', 'userBaseSelected', saving, formData.userBaseSelected, formData.userBase, (ev) => actionUpdateField(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
  </Fragment>

}
