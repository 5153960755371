import { useState, useRef, Fragment } from "react";
import { useSelector } from 'react-redux'
import Axios from "classes/axios";
import UILIB from "components";
import TableModules from "components/Table/Modules";
import { useDispatch } from "react-redux";
import { setFilterVarsMigration, getGridChip, clearUserFilter, actionOpenDrawer, tableContainer, getYesNo, MigrationUserContainer } from '../functions';
import { toast } from 'react-toastify';


export default function UserMigrationTable({ selectedCustomer, setSelectedCustomer, defaultSelected, links }) {
  
  const [expandAll, setExpandAll] = useState(false);
  

  const headerData = [
    { 
      label: <TableModules.ExpandContainer getState={expandAll} setState={setExpandAll} />, 
      value: 'nestedData', 
      filtering: false, 
      minWidth: 20, 
      maxWidth: 20
    },
    { label: '', value: 'selected', type: '', filtering: false, minWidth: 30, maxWidth: 30 },
    { label: 'Company Name', value: 'companyName', type: 'string', filtering: true, minWidth: 300, maxWidth: 300, clickable: true },
    { label: 'Account #', value: 'reference', type: 'string', filtering: true, minWidth: 80, maxWidth: 80, clickable: true },
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 80, maxWidth: 80, clickable: true },  
    { label: 'Region', value: 'regionName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Address Line 1', value: 'address1', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Address Line 2', value: 'address2', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Town', value: 'town', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Postcode', value: 'postCode', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Users', value: 'userCount', type: 'number', filtering: true, minWidth: 50, maxWidth: 50, clickable: true },
    { label: 'Grade', value: 'grading', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Source', value: 'source', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Status', value: 'statusTxt', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Audit History', value: 'auditHistory', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() }
  ];

  const headDataUsers = [
    { label: 'First Name', value: 'firstName', type: 'string', filtering: false }];

  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const tablePageDefaults = { 
    expandData: { key: 'nestedData', active: true },
    deleteEnabled: false, 
    paging: { 
      pages: [10, 20, 50, 100, 200], 
      pageSelected: 50, 
      limit: 50, 
      offset: 0, 
      orderBy: 'companyName', 
      orderDir: 'ASC' 
    } };
  const defaultDialogue = { userCount: 0, customerCount: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const entityData = useRef({});
  const requestCheckbox = useRef({});
  const [remoteRender, setRemoteRender] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const [migrating, setMigrating] = useState(false);
  const [showMigrateDialogue, setShowMigrateDialogue] = useState(defaultDialogue);
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [filter, setFilter] = useState({ button: 'NotMigrated' });
  
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was unable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404,422];

  const isMigratedView = filter.button === 'Migrated';

  const handleButtonsChanged = (buttonName, set) => {
    set({ button: buttonName });
  };

  const handleMigration = async () => {
    try {
      setMigrating(true);
      const filtered = entityData.current.result.filter(x => showMigrateDialogue.ids.includes(Number(x.id)));
      
      const apiEndpoint = isMigratedView ? 'actionRollbackPortal' : 'actionMigratePortal';
      const result = await Axios.put(`/entities/workflow/portals/${apiEndpoint}/`, filtered);

      if(exceptions.includes(result.status)) {
        if(result.status === 400) toast.error({ error: true, message: invalidConfig });
        if(result.status === 404) toast.error({ error: true, message: notFoundError });
      } else {
        entityData.current.result = entityData.current.result.filter(x => !showMigrateDialogue.ids.includes(Number(x.id)));
        Object.keys(requestCheckbox.current).forEach(x => requestCheckbox.current[x] = false);
        setMigrating(false);
        setShowMigrateDialogue(defaultDialogue);
        setBannerError(defaultBanner);
        setLocalRender(!localRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
      toast.error(exceptionError);
      setMigrating(false);
    }
  }

  const actionSelected = (row) => {
    requestCheckbox.current[row.id] = !requestCheckbox.current[row.id];
    setLocalRender(!localRender);
  };

  async function constructTable(migrationData) {

    const tableData = migrationData.map(row => {

      const b_hasExceptions = Boolean(row.userbaseMigrationEvent && row.userbaseMigrationEvent.some(event => event.migrationTypeId === 2));
      const b_hasAuditHistory = Boolean(row.userbaseMigrationEvent && row.userbaseMigrationEvent.length);
      const alertsColour = (b_hasExceptions) ? 'colour colour background-6 red' : (b_hasAuditHistory) ? 'colour colour background-8 green' : 'colour lightGrey';
      const fn_audit = actionOpenDrawer.bind(null, 'ViewAuditDrawer', row, dispatch, b_hasAuditHistory);

      const nestedData = row.userBase
        .sort((a, b) => 
          Number((Boolean(b.autoGenerated)) ? 2 : Boolean(b.isAdmin) ? 1 : 0) - Number((Boolean(a.autoGenerated)) ? 2 : Boolean(a.isAdmin) ? 1 : 0))
        .map(x => {
          const fn_viewCustomer = window.open.bind(null, `${links?.legacyPortal}?authtoken=${x.apiKey}&oid=${account.azureId}`,'_blank', 'noopener noreferrer');
          const viewCustomer = <UILIB.Button className={"button table text-small width-100 secondary"} value={'Login'} onClick={() => fn_viewCustomer()} />
          
          const userType = (Boolean(x.autoGenerated)) ? 2 : Boolean(x.isAdmin) ? 1 : 0;
          const userTypeChip = getGridChip('admin', userType);
          const container = MigrationUserContainer(x, viewCustomer, userTypeChip);

          return { firstName: { value: container, raw: container } };
        })

      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox checked={requestCheckbox.current[row.id]} type='checkbox' onChange={() => actionSelected(row)}/>} /> },
        id: { value: row.id, raw: row.id },
        companyName: { value: row.companyName, raw: row.companyName },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        reference: { value: row.reference, raw: row.reference },
        regionName: { value: row.regionName, raw: row.regionName },
        address1: { value: row.address1, raw: row.address1 },
        address2: { value: row.address2, raw: row.address2 },
        town: { value: row.town, raw: row.town },
        postCode: { value: row.postCode, raw: row.postCode },
        userCount: { value: row.userCount, raw: row.userCount },
        source: { value: getGridChip('createSource', row.source ), raw: row.source },
        grading: { value: getGridChip('createGrading', row.grading ), raw: row.grading },
        statusTxt: { value: getGridChip('user', row.status, row.isActive), raw: row.statusTxt },
        auditHistory: { value: tableContainer(fn_audit, 'icon-eye', (b_hasAuditHistory) ? 'View Audit History' : 'Audit History Unavailable', alertsColour, true), raw: b_hasAuditHistory },
        nestedData: { 
          value: 0,
          raw: {
            isOpen: (nestedData.length) ? expandAll : false,
            uniqueKey: row.id,
            nested: true, 
            expand: Boolean(nestedData.length), 
            entityData: nestedData, 
            headerData: headDataUsers, 
            tableSettings: { 
              headerEnabled: false 
            },
            className: {
              tableContainerStyle: 'pad-2'
            }
          }
        }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const expandArray = ['userbaseCustomer','userbaseMigrationEvent', 'users'];
    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVarsMigration(isMigratedView);
    const pathString = isMigratedView ? "portalsV3_userbases/getMigrationSummary" : "portals_userbases/getMigrationSummary";

    entityData.current = await Axios.get(`/entities/${pathString}/?&$filter=${filterButtons} ${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }

  const handleMigrateClicked = async () => {
    setBannerError(defaultBanner);
    const ids = Object.keys(requestCheckbox.current)
      .filter(x => requestCheckbox.current[x])
      .map(x => Number(x));

    const userCount = entityData.current.result
      .filter(x => ids.includes(Number(x.id)))
      .reduce((sum, customer) => sum + customer.userCount, 0);

    setShowMigrateDialogue({ ids, userCount, customerCount: ids.length, show: true });
  };

  return (
  <Fragment>
    {showMigrateDialogue.show && <UILIB.MessageBox 
      header={`${isMigratedView ? 'Rollback' : 'Migrate'}`} 
      loading={migrating}
      errored={bannerError}
      text={`Click OK to ${isMigratedView ? 'rollback' : 'migrate'} the ${showMigrateDialogue.customerCount} selected ${(showMigrateDialogue.customerCount) > 1 ? 'customers' : 'customer'}`}
      subText={`(${showMigrateDialogue.userCount} ${(showMigrateDialogue.userCount) > 1 ? 'users' : 'user'})`}
      onConfirm={async () => await handleMigration()} 
      onCancel={() => setShowMigrateDialogue({ ...showMigrateDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={'mar-r5 small ' + (!filter.button || !isMigratedView ? 'primary' : 'secondary')} name='NotMigrated' value='Not Migrated' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || isMigratedView ? 'primary' : 'secondary')} name='Migrated' value='Migrated' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
        </div>
        <div className='flex-item end'>
          <UILIB.Button disabled={isMigratedView || !Object.values(requestCheckbox.current).some(x => x)} className={"mar-l20 mar-r5 small green"} name="resetFilters" value="Migrate Users" onClick={() => handleMigrateClicked()} />
          <UILIB.Button disabled={!isMigratedView || !Object.values(requestCheckbox.current).some(x => x)} name="resetFilters" value="Rollback Users" onClick={() => handleMigrateClicked()} />
        </div>
      </div>
      <div className="flex-item start width-100 mar-t5">
        {selectedCustomer.selected && <p className="flex-item start width-80 text-11">
          <i>Currently filtering as {selectedCustomer.customerName} - to clear the filter {clearUserFilter(defaultSelected, setSelectedCustomer, remoteRender, setRemoteRender)}</i></p>}
      </div>
      <div className='width-100'>
        <UILIB.TableNew
          name='PortalUserMigration'
          className='small'
          overflowX='auto'
          overflowY='hidden'
          header={headerData}
          localQuery={() => constructTable((entityData.current.result && entityData.current.result.length) ? entityData.current.result : [])}
          localRender={[localRender, expandAll, links]}
          remoteQuery={getTableData}
          remoteRender={[remoteRender, filter]}
          defaultSettings={tablePageDefaults} />
      </div>
    </UILIB.Paper>
  </Fragment>
)}