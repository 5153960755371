import { Fragment } from 'react';
import { createSelectInput } from 'classes/controls/formControls';
import { BsArrowDown } from "react-icons/bs";


export default function SelectAction({ formData, saving, actionUpdateField }) {

  const userAction = [
    { value: -1, label: 'Please Select' }, 
    { value: 1, label: 'Convert to User' },
    { value: 2, label: 'Convert to Admin' },
    { value: 3, label: 'DELETE Owner' }
  ]

  return <Fragment>
    <div className="flex-item center align-center row width-100 mar-t5">
      <BsArrowDown />
    </div>
    <div className="flex-item start row width-100 mar-t5">
      <div className="flex-item center align-center font-weight-bold text-14 width-100">Select Action</div>
      <p className="flex-item start width-80 text-11 mar-l5 mar-t2">
        Please choose what should happen to the existing Owner. You can either convert the Owner to a User or Admin, or delete them
      </p>
    </div>
    {createSelectInput('', 'userActionSelected', saving, formData.userActionSelected, userAction, (ev) => actionUpdateField(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
  </Fragment>

}