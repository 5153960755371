import { Fragment } from 'react';
import { createSelectInput } from 'classes/controls/formControls';


export default function SelectMode({ formData, saving, actionUpdateField }) {

  const context = [
    { value: -1, label: 'Please Select' }, 
    { value: 1, label: 'Existing User' },
    { value: 2, label: 'New User' }
  ]

  return <Fragment>
    <div className='flex-container row start width-100'>
      <div className="flex-item start row width-100 mar-t5">
        <div className="flex-item center align-center font-weight-bold text-14 mar-t20 width-100">Select Mode</div>
        <p className="flex-item start width-80 text-11 mar-l5 mar-t2">
          You can either convert an existing Portal User or Admin to an Owner, or create a new Owner
        </p>
      </div>
      {createSelectInput('', 'context', saving, formData.context, context, (ev) => actionUpdateField(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
    </div>
  </Fragment>

}
