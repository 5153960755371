import { Fragment } from 'react';
import { createTextInput } from 'classes/controls/formControls';
import { BsArrowDown } from "react-icons/bs";


export default function AddNewOwner({ formData, saving, actionUpdateNewUserForm }) {

  return <Fragment>
    <div className="flex-item center align-center row width-100 mar-t5">
      <BsArrowDown />
    </div>
    <div className="flex-item start row width-100 mar-t5">
      <div className="flex-item center align-center font-weight-bold text-14 width-100">Add New Owner</div>
      <p className="flex-item start width-80 text-11 mar-l5 mar-t2">
        Please fill out the following details to enable the creation of a new Portal Owner
      </p>
    </div>
    <div className='flex-container row start width-100'>
      {createTextInput('First Name *', 'firstName', saving, formData.firstName, (ev) => actionUpdateNewUserForm(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
      {createTextInput('Last Name *', 'lastName', saving, formData.lastName, (ev) => actionUpdateNewUserForm(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
      {createTextInput('Email Address *', 'emailAddress', saving, formData.emailAddress, (ev) => actionUpdateNewUserForm(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
    </div>
  </Fragment>

}
