import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';


export default function CurrentOwner({ formData }) {

  return <Fragment>
    <div className="flex-item center align-center font-weight-bold text-14 mar-t20 mar-b10 width-100">Current Owner</div>
    <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
      <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">First Name:</p>
      <p className="flex-item start flex-basis-60 text-13">{formData.existingOwner.firstName}</p>
    </div>
    <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
      <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20">Last Name:</p>
      <p className="flex-item start flex-basis-60 text-13">{formData.existingOwner.lastName}</p>
    </div>
    <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
      <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20 width-40">Email Address:</p>
      <p className="flex-item start flex-basis-60 text-13">{formData.existingOwner.emailAddress}</p>
    </div>
    <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
      <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20 width-40">Last Online:</p>
      <p className="flex-item start flex-basis-60 text-13">{formatDateTime(formData.existingOwner.lastOnDate, "DD/MM/YYYY HH:mm:ss")}</p>
    </div>
    <div className="flex-container row width-100 colour background-4 lightGrey mar-b2">
      <p className="flex-item start flex-basis-40 text-13 pad-l5 pad-r20 width-40">Login Count:</p>
      <p className="flex-item start flex-basis-60 text-13">{formData.existingOwner.loginCount}</p>
    </div>
  </Fragment>

}
