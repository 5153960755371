import { Fragment } from 'react';
import UILIB from 'components';
import { BsArrowDown } from "react-icons/bs";


export default function ConfirmAction({ formData, saving, allowed, bannerError, actionUpdateCheckBox, actionSaveForm }) {

  return <Fragment>
    <div className='flex-container row start width-100 pad-t10'>
      <div className="flex-item center align-center row width-100 mar-t5">
        <BsArrowDown />
      </div>
      <div className="flex-item center align-center font-weight-bold text-14 width-100">Confirm Action</div>
      <UILIB.Checkbox checked={formData.confirm} className="flex-item align-center mar-r10 " name="confirm" onChange={(ev) => actionUpdateCheckBox(ev)} />
      <i className="flex-item start width-80 text-11 mar-l5 mar-t2">
        Please tick to confirm you are sure you wish to action this change to the Portal Owner
      </i>
      <div className='flex-container row start width-100 mar-t20 mar-r5'>
        <UILIB.Button className='green' disabled={(saving || !allowed)} loading={saving} value='Apply Changes' onClick={async () => await actionSaveForm()} />
      </div>
      {bannerError.error && <div className="flex-container row start width-100 mar-l5">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>
}
